$columns: 12;
$atom: (100% / $columns);

$break_xs: 320px;
$break_sm: 576px;
$break_md: 768px;
$break_lg: 992px;
$break_xl: 1200px;

$offset: 30px;
$offset_xs: $offset;
$offset_sm: $offset;
$offset_md: $offset;
$offset_lg: $offset;
$offset_xl: $offset;

$offset_one_side: ($offset / 2);
$offset_xs_one_side: ($offset_xs / 2);
$offset_sm_one_side: ($offset_sm / 2);
$offset_md_one_side: ($offset_md / 2);
$offset_lg_one_side: ($offset_lg / 2);
$offset_xl_one_side: ($offset_xl / 2);

$fields: 15px;

@mixin container-full(){
    padding-left: $fields;
    padding-right: $fields;
}

@mixin row-offsets(){
    margin-left: ($offset_one_side * -1);
    margin-right: ($offset_one_side * -1);
}

@mixin col-offsets($type){
    #{$type}-left: $offset_one_side;
    #{$type}-right: $offset_one_side;
}

@mixin container(){
    max-width: 1280px;
    margin: 0 auto;
    @include container-full();
}

@mixin row-flex(){
    display: flex;
    flex-wrap: wrap;
    @include row-offsets();
}

@mixin row-float(){
    @include row-offsets();
    @include clearfix();
}

@mixin row-ib(){
    @include row-offsets();
}

@mixin col(){
    box-sizing: border-box;
    word-wrap: break-word;
    @include col-offsets(margin);
}

@mixin col-float(){
    float: left;
}

@mixin col-ib(){
    display: inline-block;
    vertical-align: top;
}

@mixin col-padding(){
    word-wrap: break-word;
    @include col-offsets(padding);
}

@mixin size($n){
    $val: 100% / $columns * $n;
    width: #{calc(#{$val} - #{$offset})};
}

@mixin size-xs($n){
    $val: 100% / $columns * $n;
    @include xs(width, #{calc(#{$val} - #{$offset_xs})});
}

@mixin size-sm($n){
    $val: 100% / $columns * $n;
    @include sm(width, #{calc(#{$val} - #{$offset_sm})});
}

@mixin size-md($n){
    $val: 100% / $columns * $n;
    @include md(width, #{calc(#{$val} - #{$offset_md})});
}

@mixin size-lg($n){
    $val: 100% / $columns * $n;
    @include lg(width, #{calc(#{$val} - #{$offset_lg})});
}

@mixin size-xl($n){
    $val: 100% / $columns * $n;
    @include xl(width, #{calc(#{$val} - #{$offset_xl})});
}

@mixin shift($n){
    $val: 100% / $columns * $n;
    margin-left: #{calc(#{$val} + #{$offset_one_side})};
    margin-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-left($n){
    $val: 100% / $columns * $n;
    margin-left: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-right($n){
    $val: 100% / $columns * $n;
    margin-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding($n){
    $val: 100% / $columns * $n;
    padding-left: #{calc(#{$val} + #{$offset_one_side})};
    padding-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding-left($n){
    $val: 100% / $columns * $n;
    padding-left: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-padding-right($n){
    $val: 100% / $columns * $n;
    padding-right: #{calc(#{$val} + #{$offset_one_side})};
}

@mixin shift-xs($n){
    $val: 100% / $columns * $n;
    @include xs(margin-left, #{calc(#{$val} + #{$offset_xs_one_side})});
    @include xs(margin-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-left($n){
    $val: 100% / $columns * $n;
    @include xs(margin-left, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-right($n){
    $val: 100% / $columns * $n;
    @include xs(margin-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding($n){
    $val: 100% / $columns * $n;
    @include xs(padding-left, #{calc(#{$val} + #{$offset_xs_one_side})});
    @include xs(padding-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding-left($n){
    $val: 100% / $columns * $n;
    @include xs(padding-left, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-xs-padding-right($n){
    $val: 100% / $columns * $n;
    @include xs(padding-right, #{calc(#{$val} + #{$offset_xs_one_side})});
}

@mixin shift-sm($n){
    $val: 100% / $columns * $n;
    @include sm(margin-left, #{calc(#{$val} + #{$offset_sm_one_side})});
    @include sm(margin-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-left($n){
    $val: 100% / $columns * $n;
    @include sm(margin-left, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-right($n){
    $val: 100% / $columns * $n;
    @include sm(margin-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding($n){
    $val: 100% / $columns * $n;
    @include sm(padding-left, #{calc(#{$val} + #{$offset_sm_one_side})});
    @include sm(padding-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding-left($n){
    $val: 100% / $columns * $n;
    @include sm(padding-left, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-sm-padding-right($n){
    $val: 100% / $columns * $n;
    @include sm(padding-right, #{calc(#{$val} + #{$offset_sm_one_side})});
}

@mixin shift-md($n){
    $val: 100% / $columns * $n;
    @include md(margin-left, #{calc(#{$val} + #{$offset_md_one_side})});
    @include md(margin-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-left($n){
    $val: 100% / $columns * $n;
    @include md(margin-left, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-right($n){
    $val: 100% / $columns * $n;
    @include md(margin-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding($n){
    $val: 100% / $columns * $n;
    @include md(padding-left, #{calc(#{$val} + #{$offset_md_one_side})});
    @include md(padding-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding-left($n){
    $val: 100% / $columns * $n;
    @include md(padding-left, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-md-padding-right($n){
    $val: 100% / $columns * $n;
    @include md(padding-right, #{calc(#{$val} + #{$offset_md_one_side})});
}

@mixin shift-lg($n){
    $val: 100% / $columns * $n;
    @include lg(margin-left, #{calc(#{$val} + #{$offset_lg_one_side})});
    @include lg(margin-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-left($n){
    $val: 100% / $columns * $n;
    @include lg(margin-left, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-right($n){
    $val: 100% / $columns * $n;
    @include lg(margin-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding($n){
    $val: 100% / $columns * $n;
    @include lg(padding-left, #{calc(#{$val} + #{$offset_lg_one_side})});
    @include lg(padding-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding-left($n){
    $val: 100% / $columns * $n;
    @include lg(padding-left, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-lg-padding-right($n){
    $val: 100% / $columns * $n;
    @include lg(padding-right, #{calc(#{$val} + #{$offset_lg_one_side})});
}

@mixin shift-xl($n){
    $val: 100% / $columns * $n;
    @include xl(margin-left, #{calc(#{$val} + #{$offset_xl_one_side})});
    @include xl(margin-right, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin shift-xl-left($n){
    $val: 100% / $columns * $n;
    @include xl(margin-left, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin shift-xl-right($n){
    $val: 100% / $columns * $n;
    @include xl(margin-right, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin shift-xl-padding($n){
    $val: 100% / $columns * $n;
    @include xl(padding-left, #{calc(#{$val} + #{$offset_xl_one_side})});
    @include xl(padding-right, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin shift-xl-padding-left($n){
    $val: 100% / $columns * $n;
    @include xl(padding-left, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin shift-xl-padding-right($n){
    $val: 100% / $columns * $n;
    @include xl(padding-right, #{calc(#{$val} + #{$offset_xl_one_side})});
}

@mixin xs($name, $value){
    @media screen and (min-width: $break_xs){
        #{$name}: $value;
    }
}

@mixin xs-block(){
    @media screen and (min-width: $break_xs){
        @content;
    }
}

@mixin sm($name, $value){
    @media screen and (min-width: $break_sm){
        #{$name}: $value;
    }
}

@mixin sm-block(){
    @media screen and (min-width: $break_sm){
        @content;
    }
}

@mixin md($name, $value){
    @media screen and (min-width: $break_md){
        #{$name}: $value;
    }
}

@mixin md-block(){
    @media screen and (min-width: $break_md){
        @content;
    }
}

@mixin lg($name, $value){
    @media screen and (min-width: $break_lg){
        #{$name}: $value;
    }
}

@mixin lg-block(){
    @media screen and (min-width: $break_lg){
        @content;
    }
}

@mixin xl($name, $value){
    @media screen and (min-width: $break_xl){
        #{$name}: $value;
    }
}

@mixin xl-block(){
    @media screen and (min-width: $break_xl){
        @content;
    }
}

@mixin from($min_width){
    @media screen and (min-width: $min_width){
        @content;
    }
}

@mixin to($max_width){
    @media screen and (max-width: $max_width){
        @content;
    }
}

@mixin from-to($min_width, $max_width){
    @media screen and (min-width: $min_width) and (max-width: $max_width){
        @content;
    }
}

@mixin u-row-flex($offset){
    display: flex;
    flex-wrap: wrap;
    margin-left: ($offset / -2);
    margin-right: ($offset / -2);
}

@mixin u-col($offset){
    box-sizing: border-box;
    word-wrap: break-word;
    margin-left: ($offset / 2);
    margin-right: ($offset / 2);
}

@mixin u-size($n, $columns, $offset){
    width: #{calc(100% / #{$columns} * #{$n} - #{$offset})};
}

@mixin reset(){
    body,div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td{
        margin: 0;
        padding: 0;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    fieldset,img,abbr{
        border: 0;
    }
    address,caption,cite,code,dfn,em,strong,th,var{
        font-style: normal;
        font-weight: normal;
    }
    ul li{
        list-style: none;
    }
    caption,th{
        text-align: left;
    }
    h1,h2,h3,h4,h5,h6{
        font-size: 100%;
        font-weight: normal;
    }
    sup{
        vertical-align: text-top;
    }
    sub{
        vertical-align: text-bottom;
    }
    input,textarea,select{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
    legend{
        color: #000;
    }
    article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{
        display: block;
    }
    img{
        max-width: 100%;
        height: auto;
    }
}

@mixin debug($background, $outline){
    height: 100vh;
    position: fixed;
    width: 100%;
    
    > div{
        height: inherit;
        @include container();
        
        > div{
            height: inherit;
            @include row-flex();
            
            > div{
                @include col();
                @include size(1);
                height: inherit;
                outline: $outline;
                background: $background;
            }
        }
    }
}

@mixin clearfix(){
    &::after{
        display: block;
        content: "";
        clear: both;
    }
}