h1, h2, h3, h4, h5, h6, ul {
    padding: 0;
    margin: 0;
}

.container {
    @include container();
    @include sm(max-width, 510px);
    @include md(max-width, 690px);
    @include lg(max-width, 930px);
    @include xl(max-width, 1300px);
}