@import "helpers/variables";
@import "helpers/mixins";
@import "helpers/functions";
@import "vendor/normalize";
@import "vendor/smart-grid";
@import "base/fonts";
@import "base/general";
@import "../blocks/blocks";
/*slic slider*/
/* Slider */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap');
html,
body {
  height: 100%;
}
#main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1 0 auto;
}
footer {
  flex: 0 0 auto;
}
.slick-dots li.slick-active button{
    background:#F2C94C;
    opacity: 1;
}
.slick-dots
{
    position: absolute;
    bottom: 43px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 10px;
    height: 10px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button
{
    display: block;
    width: 6px;
    font-size: 0px;
    height: 6px;
    padding: 5px;
    background: #FFFFFF;
    opacity: 0.2;
    cursor: pointer;
    border-radius: 50%;
    border: 0;
    outline: none;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}


.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}
.slick-next{
    font-size: 0px;
    border: none;
    background: url(../img/forward-arrow.svg) no-repeat;
    width: 30px;
    height: 30px;
    background-size: contain;
    position: absolute;
    right: 70px;
    top: 50%;
    margin-top: -17px;
    z-index: 1;
    cursor:pointer;
}
.slick-next:hover{
    transform: rotate(180deg);
    background:url(../img/back-arrow-hover.svg) no-repeat;
    background-size: contain;
}
.slick-prev:hover{
    transform: rotate(0deg);
    background:url(../img/back-arrow-hover.svg) no-repeat;
    background-size: contain;
}
.slick-prev{
    cursor:pointer;
    font-size: 0px;
    border: none;
    background: url(../img/forward-arrow.svg) no-repeat;
    width: 35px;
    height: 35px;
    background-size: contain;
    position: absolute;
    left: 70px;
    top: 50%;
    margin-top: -17px;
    z-index: 1;
    transform: rotate(180deg);
}
.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
position: relative;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*slic slider*/
/*line-clamp*/
.line-clamp
{
	display            : block;
	display            : -webkit-box;
	-webkit-box-orient : vertical;
	position           : relative;

	line-height        : 1.2;
	overflow           : hidden;
	text-overflow      : ellipsis;
	padding            : 0 !important;
}
.line-clamp:after
{
	content    : '...';
	text-align : right;
	bottom     : 0;
	right      : 0;
	width      : 25%;
	display    : block;
	position   : absolute;
	height     : calc(1em * 1.2);
	background : linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
}
@supports (-webkit-line-clamp: 1)
{
	.line-clamp:after
	{
		display : none !important;
	}
}
.line-clamp-1
{
	-webkit-line-clamp : 1;
	height             : calc(1em * 1.2 * 1);
}
.line-clamp-2
{
	-webkit-line-clamp : 2;
	height             : calc(1em * 1.2 * 2);
}
.line-clamp-3
{
	-webkit-line-clamp : 3;
	height             : calc(1em * 1.2 * 3);
}
.line-clamp-4
{
	-webkit-line-clamp : 4;
	height             : calc(1em * 1.2 * 4);
}
.line-clamp-5
{
	-webkit-line-clamp : 5;
	height             : calc(1em * 1.2 * 5);
}
/*line-clamp*/
body{
    font-family: 'Ubuntu', sans-serif;
    color: #333333;
}
.article-h2{
font-size: 48px;
line-height: 58px;
text-align: center;
font-weight: 400;
}
.font-date,.font-city{
    font-size: 16px;
    line-height: 25px;
    color: #333333;
  
}
.font-article{
    font-size: 24px;
    line-height: 30px; 
    color: #333333;
}
.rel{
    color: #E34D4D;
    font-weight: 500;
font-size: 20px;
line-height: 30px;

&:hover{
    opacity: 0.8;  
    text-decoration: none;
}
}
header{
height:100px;
background: #E7F4F5;
 .search{
     cursor:pointer;
    width: 19px;
    height: 18px;
     background-size:contain;
     background:url(../img/search.svg) no-repeat;
 }
}

header .container{
display:flex;
align-items: center;
height: 100px;
justify-content: space-between;
}
header .menu{
    display: flex;
    justify-content: space-around;
}
header .menu a{
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: #333333;  
}
header .login{
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center; 
    color: #F2F2F2;
    background: #E34D4D;
border-radius: 4px;
padding:14px 29px;
text-decoration: none;
}
header .lang{
    font-size: 16px;
}
header .lang a{
    color: #E34D4D;
 
}
header .lang span{
    color: #333333;
}
#main-news{
    margin-top: 100px;
    padding-bottom: 80px;
}
.main-news__box{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    
    .news-rel:nth-child(3n){
        margin-right:0px;
    }
    .news-rel{
        text-decoration: none;
        height:430px;
        margin-right:30px;
        width:30%;
        background: #FFFFFF;
        border: 1px solid #BDBDBD;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 30px;
        
        &__img{
            overflow:hidden;
            height: 260px;
            border-bottom:1px solid #bdbdbd;
            img{
                width: 100%;
                object-fit: cover;
            }
        }
        &__date{
            line-height: 25px;
            padding:20px 20px 0px 20px;
        }
        &__article{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            position: relative;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 !important;
            -webkit-line-clamp: 3;
            height: calc(1em * 1.2 * 3);
            margin: 5px 20px 20px;
        }
    }
}
.all-view{
width:207px;
text-align:center;
height:42px;
line-height: 42px;
font-family: Roboto;
font-style: normal;
font-weight: 500;
font-size: 16px;
text-transform: uppercase;
color: #F2F2F2;
background: #E34D4D;
border-radius: 4px;
margin: 10px auto;
display: block;
text-decoration: none;
}
#magazine{
    background: #FAFAFA;
    padding-top: 50px;
    padding-bottom: 52px;;
    .article-h2{
    padding-bottom: 20px;;
    }
    .magazine-txt{
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        width:68%;
        margin: 0 auto;
    }
    .rel{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;  
        text-align:center;
        display: block;
        margin-top: 10px;
    }
}
footer{
    background: #067F9A;
    padding-top: 60px;
    .footer-official{
        color:#fff;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        line-height: 20px;
    }
    .fb-rel{
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        display: block;
        line-height: 25px;
        text-decoration: underline;
        margin: 48px auto;
        text-align: center;
    }
    .footer-copyright{
        text-align: center;
        border-top:1px solid #fff;
        display: flex;
        height:100px;
        color:#fff;
        align-items: center;
        justify-content: center;
    }
}
.slick-slide img{
    width: 100%;
    object-fit: cover; 
}
.overlay-slider{
    width: 100%;
    height:100%;
    bottom: 0px;
    position: absolute;
    background: linear-gradient(180deg, rgba(6, 138, 154, 0) 0%, #068A9A 100%);
}
.slider-text{
    display: flex;
    width: 560px;
    flex-direction: column;
    z-index: 999;
    position: absolute;
    top: 240px;
    left: 33%;
    margin-left: -230px;
    h3{
        font-size: 52px;
line-height: 58px;
letter-spacing: -0.0384615em;
color: #FFFFFF;
    }
    .slider-date{
        font-size: 20px;
line-height: 30px;
color: #FFFFFF;
}
    .slider-city{
        font-size: 20px;
line-height: 30px;

color: #FFFFFF;
    }
    .rel-slider{
        font-size: 20px;
line-height: 19px;
text-decoration-line: underline;
color: #F2C94C;
}
}

/*02event*/
#upcoming-events{
   margin-top: 100px;
   .upcoming-box{
    display: flex;
    flex-wrap:wrap;
    margin-top: 40px;
   } 
   .upcoming-news{
    text-decoration: none;
    height: 520px;
    margin-right: 30px;
    width: 30%;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    color:#333;
    margin-bottom: 30px;
    position:relative;
    &__img{
        overflow: hidden;
        height:260px;
        border-bottom: 1px solid #bdbdbd;
        img{
            width:100%;
            object-fit: cover;
        }
    }
    &__text{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        position: relative;
        line-height: 30px;
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 !important;
        -webkit-line-clamp: 3;
        height: calc(1em * 1.2 * 3);
        margin: 5px 20px 20px;
    }
    &__date{
        line-height: 25px;
        padding: 20px 20px 0px 20px;
    }
    &__city{
        line-height: 25px;
        padding: 0px 20px 0px 20px;
    }
    .rel{
        text-decoration: underline;
        text-align: center;
        position: absolute;
        height: 60px;
        line-height: 60px;
        display: block;
        bottom: 0px;
        width: 89.11%;
        margin: 0px 20px;
        border-top: 1px solid #bdbdbd;
    }
   }
}
.past-events{
    margin-top: 100px;
    padding-bottom: 70px;
    &__box{
        margin-top: 40px;
        width: 100%;
        .past-rel__more{
            align-items: flex-end;
            text-align: right;
            text-decoration-line: underline;
            
            color: #E34D4D;
        }
    .past-rel__box-article{
        font-size: 32px;
        line-height: 38px; 
        color: #333333;
        width: 70%;
    }
    }
    .past-rel{
        display: flex;
        height:165px;
        padding: 30px 0px;
        border-top:1px solid #bdbdbd;
        width:100%;
        text-decoration: none;
        &__img{
            margin-right: 30px;
            height:165px;
            overflow:hidden;
            width:203px;
            flex-basis: 203px;
            flex-grow: 0;
            flex-shrink: 0;
            img{
                width: 100%;
                object-fit: contain;
                height: 100%;
            }
        }
    }
}

/*02event*/

/*03-1 upcoming*/
#upcoming-event{
    padding-bottom: 100px;
    .upcoming-event{
        margin-top: 100px;
        max-width:750px;
        margin:0 auto;
       .see-report{
           display:flex;
           align-items: center;
           color:#E34D4D;
           margin-bottom: 30px;
           i{
            display:block;
            margin-right: 15px;
            background: url(../img/star.svg) no-repeat;
            width:54px;
            height:54px;
            background-size: contain;
            }
       }
        h2{
            font-weight: 500;
font-size: 32px;
line-height: 38px;
text-align: left;
margin:40px 0px 30px 0px;
        }
        &__img{
            img{
                width: 100%;
            }
        }
        &__txt{
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            border-bottom:1px solid #bdbdbd;
            margin-bottom: 20px;
            padding-bottom: 20px;
            p{

            }
            b{
                font-weight: 500;
            }
        }
        &__rel{
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration-line: underline;
            color: #E34D4D; 
        }
    }
}
/*03-1 upcoming*/
/*05 news*/
#news{
    padding-bottom: 100px;;
}
/*05 news*/
/*06-1 news*/
#news-detail{
    padding-bottom: 100px;
   .news-detail{
    margin-top: 100px;
       max-width:750px;
       margin:0 auto;
      
       &__date{
        font-size: 16px;
        line-height: 25px;
       }
       &__img{
           height:400px;
           margin-bottom: 40px;
           overflow: hidden;
           img{
            width: 100%;
            object-fit: cover;
           }
       }
       &__txt{
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        border-bottom: 1px solid #bdbdbd;
        margin-bottom: 20px;
        padding-bottom: 20px;
        .article-h2{
            font-weight: 500;
            font-size: 32px;
            line-height: 38px;
            text-align:left;
            margin-top: 6px;;
           }
       }
       &__rel{
        font-family: "Roboto";
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #E34D4D;
       }
   }
}
/*06-1 news*/
/*07 magazine*/
#magazine-aritm{
    margin-top: 100px;
    padding-bottom:100px ;
    .magazinearitm{
        max-width:750px;
        margin: 0 auto;
      .all-view{
        margin-top: 60px;
    }
        &__txt{
            font-size: 20px;
            line-height: 30px;
            margin-top: 60px;
        }
        &__contacts{
            margin-top: 20px;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 40px;
        }
        &__catalog{
            h3{
            font-size: 32px;
            line-height: 38px;
            font-weight: 400;
            margin-bottom: 20px;  
            }
        &-box{
            display: flex;
            margin:11px 0px;
            align-items: center;
            .rel{
                font-weight: 500;
                font-size: 20px;
                line-height: 19px;
                margin-top: 10px;;
            }
        }
        .magazinearitm-img{
            width:110px;
            height:145px;
            overflow:hidden;
            margin-right: 16px;
            img{
                max-width:100%;
            }
            }
        }
    }
}
.magazinearitm-date{
    font-size: 20px;
    line-height: 30px;
}
.magazinearitm-article{
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 10px;
}
/*07 magazine*/

/*08 Recomendation*/
#recomendation{
    margin-top: 100px;
    padding-bottom: 100px;
    .recomendation{
        max-width:750px;
        margin:0 auto;
        margin-bottom: 40px;
        h2{
            margin-bottom: 40px;
        }
        .recomendation-box{
            display:flex;
            height:167px;
            width:100%;
            align-items: center;
            &__img{
                margin-right: 30px;
            }
            &__right{
                .font-date{
                    font-size: 20px;
                    line-height: 30px;
                }
                .font-article{
                    font-size: 32px;
                    line-height: 38px;
                }
                .rel{
                    line-height: 19px;
                }
            }
        }
    }
}
/*08 Recomendation*/

/*09 About*/
#about{
    margin-top: 100px;
    padding-bottom: 100px;
    .about-page{
        max-width: 750px;
        margin: 0 auto;
        .article-h2{
            margin-bottom: 40px;
        }
        &__img{
            width:100%;
            overflow:hidden;
            height:400px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__txt{
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
        }
        h3{
            font-size: 32px;
            line-height: 38px;
            margin-top: 40px;
            margin-bottom: 30px;
        }
    }
    .our-team{
        .article-h2{
            margin-top: 80px;
            margin-bottom: 80px;
        }
        .team-container{
            display: flex;
            flex-wrap:wrap;
            text-align:center;
            justify-content: space-evenly;
            &__all{
                width:270px;
                justify-content: center;
                margin-bottom: 20px;
                .team-container__img{
                    margin: 0 auto;
                    margin-bottom: 10px;
                    border-radius:50%;
                    width:110px;
                    height:110px;
                    overflow:hidden;
                    img{
                        border-radius:50%;
                        width:110px;
                        height:110px;
                    }
                }
                .team-container__name{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 30px;
                }
                .team-container__describe{
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 25px;
                }
            }
        }
    }
}
/*09 About*/


/*10 contacts*/
#contacts{
    margin-top: 100px;
    
    .contacts{
        text-align:center;
        padding-bottom: 100px;
        .contacts-box{
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 20px;
        }
        h3{
            font-size: 32px;
            line-height: 38px;
            font-weight:400;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }
    #map{
        width:100%;
        img{
            width:100%;
        }
    }
}
/*10 contacts*/

/*11 profile*/
#profile{
    margin-top: 100px;
    padding-bottom: 100px;
    .article-h2{
       margin-bottom: 40px; 
    }
    .input, textarea{  
background: #F0F0F0;
border-radius: 4px;
border:none;  
font-size: 16px;
line-height: 25px;
padding-left: 20px; 
margin-bottom: 20px;
height:60px;
outline:none;
width: 100%;
box-sizing: border-box;
    }
    textarea{
        height:140px;
        padding-top:15px;
    }
    .personal-data{
        display: flex;
        margin-top: 30px;
        margin-bottom: 20px;
        justify-content: space-between;
        .left,.right{
            width:49%;
        }
    }
    .txt{
        text-align: center;
        font-size: 32px;
        line-height: 38px;     
    }
    .change-password{
        max-width:555px;
        margin:0 auto;
        .txt{
            margin-bottom: 30px;
        }
       
    }
    .subscribe{
        max-width:750px;
        margin:0 auto;
        text-align:center;
        margin-top: 60px;
        .txt{
            margin-bottom: 30px;
        }
        input[type="checkbox"]{
            width:25px;
            height:25px;
            border: 2px solid #067F9A;
            box-sizing: border-box;
            border-radius: 4px;
            background:none;
            margin-right: 10px;
        }
        &__line{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            justify-content: center;
            span{
                font-weight: 300;
                font-size: 16px;
                line-height: 25px;
            }
        }
    }
    input[type="submit"]{
        width: 207px;
        text-align: center;
        height: 42px;
        line-height: 42px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        color: #F2F2F2;
        border:none;
        background: #E34D4D;
        border-radius: 4px;
        margin: 10px auto;
        display: block;
        text-decoration: none;
    }
}
/*11 profile*/

/*12 login page*/
#authorisation{
  margin-top: 100px; 
  padding-bottom: 80px; 
  .authorisation{
      max-width:460px;
      margin:0 auto;
      .article-h2{
          margin-bottom: 20px;
      }
      h3{
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center; 
        margin-bottom: 20px;
      }
      &__fb{
         background:url(../img/fb.svg) no-repeat; 
         height:60px;
         line-height: 60px;
         background-size:cover;
         text-align:center;
         display:block;
         margin-bottom: 30px;
         color:#fff;
         text-decoration: none;
      }
      &__or{
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 25px;
        text-align:center;
      }
      .input[type="text"],.input[type="password"]{  
        background: #F0F0F0;
        border-radius: 4px;
        border:none;  
        font-size: 16px;
        line-height: 25px;
        padding-left: 20px; 
        margin-bottom: 20px;
        height:60px;
        outline:none;
        width: 100%;
        box-sizing: border-box;
        }
        &__forgot{
            margin-top:-10px;
            text-align:right;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration: underline;
            display: block;
            margin-bottom: 20px;
            color:#333;
        }
        &__submit{
            background: #067F9A;
            border-radius: 4px;
            border:none;
            color:#fff;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            width:100%;
            height:60px;
            box-sizing: border-box;
        }
        &__back{
            text-align: center;
            display: block;
            margin-top: 30px;
        }
  }
}
/*12 login page*/

/*13 sign up page*/
/*13 sign up page*/
